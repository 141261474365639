require('./bootstrap');
require('./sw');
require('./enable-push');

import flatpickr from "flatpickr";
import { Romanian } from "flatpickr/dist/l10n/ro.js";

import { Modal} from "flowbite";

window.flatpickr = flatpickr;
window.Modal = Modal;

